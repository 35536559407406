import { useMemo } from 'react';
import { DateTime } from 'luxon';
import {
    DateTimePicker as MuiDateTimePicker,
    DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';

export type DateTimePickerProps = Pick<
    MuiDateTimePickerProps<DateTime> & { timezone: string },
    | 'disabled'
    | 'disableFuture'
    | 'disablePast'
    | 'format'
    | 'inputRef'
    | 'label'
    | 'maxDate'
    | 'minDate'
    | 'name'
    | 'onChange'
    | 'onError'
    | 'openTo'
    | 'slotProps'
    | 'sx'
    | 'timezone'
    | 'value'
>;

export const DateTimePicker = ({ timezone, ...props }: DateTimePickerProps): JSX.Element => {
    const dateFormat = useMemo(() => {
        if (timezone.includes('America')) {
            return 'MM/dd/yyyy HH:mm';
        }
        return 'dd/MM/yyyy HH:mm';
    }, [timezone]);
    return <MuiDateTimePicker format={dateFormat} {...props} />;
};

DateTimePicker.displayName = 'DateTimePicker';
