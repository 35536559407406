import { theme } from '@deltasierra/react/theme';
import { toBase64 } from '@deltasierra/utilities/string';

// ? Reference https://github.com/vercel/next.js/blob/canary/examples/image-component/app/shimmer/page.tsx

type shimmerProps = { height: string; id: string };

const shimmer = ({ height, id }: shimmerProps): string => {
    const rId = `r${id}`;

    return `<svg
            height="${height}"
            version="1.1"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient id="${id}">
                    <stop offset="20%" stop-color="${theme.palette.primary.light}" />
                    <stop offset="50%" stop-color="${theme.palette.secondary.contrastText}" />
                    <stop offset="70%" stop-color="${theme.palette.primary.light}" />
                </linearGradient>
            </defs>
            <rect fill="${theme.palette.primary.light}" height="100%" width="100%" />
            <rect fill="url(#${id})" height="100%" id="${rId}" width="100%" />
            <animate attributeName="x" dur="1s" from="-100%" repeatCount="indefinite" to="100%" xlink:href="#${rId}" />
        </svg>`;
};

export const shimmerDataImageString = (height: string, id: string): `data:image/${string}` =>
    `data:image/svg+xml;base64,${toBase64(shimmer({ height, id }))}`;
