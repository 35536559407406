export function splitTextIntoTextWithLinks(text: string): Array<
    | {
          text: string;
          type: 'link';
      }
    | {
          text: string;
          type: 'text';
      }
> {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map(part => ({
        text: part,
        type: urlRegex.test(part) ? 'link' : 'text',
    }));
}
