import { graphql } from '../../__graphqlTypes';

export const GET_LOCATION_MERGE_FIELDS = graphql(`
    query GetLocationMergeFields($id: ID!, $input: GetLocationBuildableTemplateMergeFieldsInput!) {
        location(id: $id) {
            id
            buildableTemplateMergeFields(input: $input) {
                ... on LocationBuildableTemplateMergeField {
                    categories
                    field
                    values {
                        # For now we have only implemented generic merge fields
                        ... on LocationBuildableTemplateMergeFieldValue {
                            __typename
                            value
                            isFallback
                        }
                    }
                }
            }
        }
    }
`);
