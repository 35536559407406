import { Slider as MuiSlider, SliderProps as MuiSliderProps } from '@mui/material';

export type SliderProps = Pick<
    MuiSliderProps,
    | 'aria-label'
    | 'color'
    | 'defaultValue'
    | 'getAriaLabel'
    | 'getAriaValueText'
    | 'marks'
    | 'max'
    | 'min'
    | 'onChange'
    | 'slots'
    | 'step'
    | 'style'
    | 'sx'
    | 'value'
    | 'valueLabelDisplay'
    | 'valueLabelFormat'
>;

export const Slider = (props: SliderProps): JSX.Element => <MuiSlider {...props} />;

Slider.displayName = 'Slider';
