import { graphql } from '../../__graphqlTypes';

export const GET_ALL_PLATFORMS_AND_LOCATIONS = graphql(`
    query getAllPlatformsAndLocations($id: ID!) {
        platforms {
            edges {
                node {
                    id
                    name
                    displayName
                }
            }
        }
        location(id: $id) {
            id
            title
            timezone
            integrationConnection {
                edges {
                    node {
                        id
                        name
                        isReady
                    }
                }
            }
        }
    }
`);
