'use client';

import { useSuspenseQuery } from '@apollo/client';
import {
    GET_ALL_PLATFORMS_AND_LOCATIONS,
    getAllPlatformsAndLocationsQuery,
    getAllPlatformsAndLocationsQueryVariables,
} from '@deltasierra/frontend/graphql';
import { useMemo } from 'react';
import { Platforms, IntegrationsEnum } from './types';

export function useEnabledIntegrations(locationId: string): {
    platformOptions: Platforms;
    locationData: getAllPlatformsAndLocationsQuery['location'];
} {
    const {
        data: { location: locationData, platforms },
    } = useSuspenseQuery<getAllPlatformsAndLocationsQuery, getAllPlatformsAndLocationsQueryVariables>(
        GET_ALL_PLATFORMS_AND_LOCATIONS,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                id: locationId,
            },
        },
    );

    const connectedPlatforms = useMemo(() => {
        const connected: IntegrationsEnum[] = [];

        const campaignMonitorIntegration = locationData?.integrationConnection.edges.find(
            edge => edge.node.name === 'campaignMonitor',
        );
        if (campaignMonitorIntegration?.node.isReady) {
            connected.push(IntegrationsEnum.CAMPAIGN_MONITOR);
        }

        const clubReadyIntegration = locationData?.integrationConnection.edges.find(
            edge => edge.node.name === 'clubReady',
        );
        if (clubReadyIntegration?.node.isReady) {
            connected.push(IntegrationsEnum.CLUB_READY);
        }

        return connected;
    }, [locationData?.integrationConnection.edges]);

    const platformOptions = useMemo(
        () =>
            platforms.edges
                .map(({ node: { name, ...node } }) => ({
                    ...node,
                    name: name as IntegrationsEnum,
                }))
                .filter(node => connectedPlatforms.includes(node.name)),
        [connectedPlatforms, platforms.edges],
    );

    return {
        locationData,
        platformOptions,
    };
}
