export enum IntegrationsEnum {
    CAMPAIGN_MONITOR = 'campaignMonitor',
    CLUB_READY = 'clubReady',
}

export enum PlatformsEnum {
    CAMPAIGN_MONITOR = 'campaignMonitor',
    CLUB_READY = 'clubReady',
    DOWNLOAD = 'download',
}
