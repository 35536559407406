import { toBase64 } from '@deltasierra/utilities/string';

type blankTemplateTripleProps = { color: string };

const blankTemplateTriple = ({ color }: blankTemplateTripleProps): string =>
    `<svg fill="none" height="123" viewBox="0 0 322 123" width="322" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_697_29106)">
            <path d="M345.629 173H-29.3707V-53H345.629V173Z" fill="#E6E6E6" />
            <path d="M163.095 106.817H7.72827V13.1828H163.095V106.817Z" fill="white" />
            <path d="M158.715 20.9443H136.849V16.4082H158.715V20.9443Z" fill="#E6E6E6" />
            <path d="M16.9711 21.1282H12.0892V16.2245H16.9711V21.1282Z" fill="#E6E6E6" />
            <path d="M105.023 67.6594H65.8003V28.2625H105.023V67.6594Z" fill="${color}" />
            <path d="M158.715 76.7665H12.0892V73.2846H158.715V76.7665Z" fill="#E6E6E6" />
            <path d="M158.715 89.0776H12.0892V85.596H158.715V89.0776Z" fill="#E6E6E6" />
            <path d="M158.715 95.4806H12.0892V91.9989H158.715V95.4806Z" fill="#E6E6E6" />
            <path d="M158.715 101.789H12.0892V98.3074H158.715V101.789Z" fill="#E6E6E6" />
            <path d="M158.715 82.8479H12.0892V79.3662H158.715V82.8479Z" fill="#E6E6E6" />
            <path d="M158.715 76.7665H12.0892V73.2846H158.715V76.7665Z" fill="#E6E6E6" />
            <path d="M238.987 106.33H174.469V13.6702H238.987V106.33Z" fill="white" />
            <path d="M221.986 56.4789H191.47V25.8285H221.986V56.4789Z" fill="${color}" />
            <path d="M181.349 20.5168H177.353V16.5026H181.349V20.5168Z" fill="#E6E6E6" />
            <path d="M235.929 20.5168H219.154V16.5026H235.929V20.5168Z" fill="#E6E6E6" />
            <path d="M235.929 61.988H177.353V59.4688H235.929V61.988Z" fill="#E6E6E6" />
            <path d="M235.929 65.8372H177.353V63.3183H235.929V65.8372Z" fill="#E6E6E6" />
            <path d="M235.929 70.1292H177.353V67.6101H235.929V70.1292Z" fill="#E6E6E6" />
            <path d="M235.929 74.1514H177.353V71.6323H235.929V74.1514Z" fill="#E6E6E6" />
            <path d="M235.929 80.1682H208.923V77.6492H235.929V80.1682Z" fill="#E6E6E6" />
            <path d="M235.929 84.0175H208.923V81.4982H235.929V84.0175Z" fill="#E6E6E6" />
            <path d="M235.929 88.3094H208.923V85.7902H235.929V88.3094Z" fill="#E6E6E6" />
            <path d="M235.929 92.3317H208.923V89.8124H235.929V92.3317Z" fill="#E6E6E6" />
            <path d="M235.929 96.95H208.923V94.4309H235.929V96.95Z" fill="#E6E6E6" />
            <path d="M235.929 100.972H208.923V98.4531H235.929V100.972Z" fill="#E6E6E6" />
            <path d="M205.614 101.183H177.328V77.8256H205.614V101.183Z" fill="#E6E6E6" />
            <path d="M308.53 105.359H252.491V13.1827H308.53V105.359Z" fill="white" />
            <path d="M305.992 75.4174H282.681V72.8983H305.992V75.4174Z" fill="#E6E6E6" />
            <path d="M305.992 79.2666H282.681V76.7476H305.992V79.2666Z" fill="#E6E6E6" />
            <path d="M305.992 83.5586H282.681V81.0394H305.992V83.5586Z" fill="#E6E6E6" />
            <path d="M305.992 87.5808H282.681V85.0616H305.992V87.5808Z" fill="#E6E6E6" />
            <path d="M305.992 92.1992H282.681V89.6802H305.992V92.1992Z" fill="#E6E6E6" />
            <path d="M305.992 96.2214H282.681V93.7022H305.992V96.2214Z" fill="#E6E6E6" />
            <path d="M279.824 96.4325H255.409V73.0747H279.824V96.4325Z" fill="#E6E6E6" />
            <path d="M258.952 19.5659H255.008V15.7735H258.952V19.5659Z" fill="#E6E6E6" />
            <path d="M306.373 19.5659H291.226V15.7735H306.373V19.5659Z" fill="#E6E6E6" />
            <path d="M293.049 47.7952H268.351V22.9879H293.049V47.7952Z" fill="${color}" />
            <path d="M305.992 54.0531H255.409V51.5341H305.992V54.0531Z" fill="#E6E6E6" />
            <path d="M305.992 57.9021H255.409V55.3831H305.992V57.9021Z" fill="#E6E6E6" />
            <path d="M305.992 62.1941H255.409V59.6751H305.992V62.1941Z" fill="#E6E6E6" />
            <path d="M305.992 66.2162H255.409V63.6972H305.992V66.2162Z" fill="#E6E6E6" />
            <path d="M305.992 70.5555H255.409V68.0365H305.992V70.5555Z" fill="#E6E6E6" />
            <path d="M305.992 100.885H255.409V98.3664H305.992V100.885Z" fill="#E6E6E6" />
        </g>
        <defs>
            <clipPath id="clip0_697_29106">
                <rect fill="white" height="123" width="322" />
            </clipPath>
        </defs>
    </svg>`;

blankTemplateTriple.displayName = 'blankTemplateTriple';

export const blankTemplateTripleImageString = (color: string): `data:image/${string}` =>
    `data:image/svg+xml;base64,${toBase64(blankTemplateTriple({ color }))}`;
